import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <iframe
        title="geoportail-map"
        src="https://www.geoportail.gouv.fr/embed/visu.html?c=0.03848295831176146,49.025461717971524&z=7&l0=GEOGRAPHICALGRIDSYSTEMS.PLANIGNV2::GEOPORTAIL:OGC:WMTS(1;g)&l1=SECUROUTE.TE.1TE::GEOPORTAIL:OGC:WMTS(1)&l2=SECUROUTE.TE.ALL::GEOPORTAIL:OGC:WMS(1)&permalink=yes"
        frameBorder="0"
        allowFullScreen
        sandbox="allow-forms allow-scripts allow-same-origin"
      ></iframe>
    </div>
  );
}

export default App;
